import React from 'react';

const TradeProgressBar = ({ longPercentage, longProfitablePercentage, shortProfitablePercentage }) => {
  const shortPercentage = 100 - longPercentage;

  return (
    <div className="progress-bar-container">
      <div className="bar-section">
        <div className="percentage-labels">
          <span className="long-label">Long: {longPercentage.toFixed(2)}%</span>
          <span className="short-label">Short: {shortPercentage.toFixed(2)}%</span>
        </div>
        <div className="progress-bar">
          <div className="bar-background"></div>
          <div className="bar long-bar" style={{ width: `${longPercentage}%` }}></div>
          <div className="bar short-bar" style={{ width: `${shortPercentage}%`, left: `${longPercentage}%` }}></div>
        </div>
      </div>

      <div className="bar-section">
        <div className="percentage-labels">
          <span className="long-profitable-label">Long Profitable: {longProfitablePercentage.toFixed(2)}%</span>
        </div>
        <div className="progress-bar">
          <div className="bar-background"></div>
          <div className="bar long-profitable-bar" style={{ width: `${longProfitablePercentage}%` }}></div>
        </div>
      </div>

      <div className="bar-section">
        <div className="percentage-labels">
          <span className="short-profitable-label">Short Profitable: {shortProfitablePercentage.toFixed(2)}%</span>
        </div>
        <div className="progress-bar">
          <div className="bar-background"></div>
          <div className="bar short-profitable-bar" style={{ width: `${shortProfitablePercentage}%` }}></div>
        </div>
      </div>

      <style jsx>{`
        .progress-bar-container {
          width: 100%;
          max-width: 400px;
          margin: 0 auto;
          font-family: Arial, sans-serif;
          color: var(--text-color);
        }
        .bar-section {
          margin-bottom: 16px;
        }
        .percentage-labels {
          display: flex;
          justify-content: space-between;
          margin-bottom: 4px;
          font-size: 14px;
        }
        .long-label, .long-profitable-label {
          color: var(--primary-color);
        }
        .short-label, .short-profitable-label {
          color: var(--text-color);
        }
        .progress-bar {
          height: 8px;
          position: relative;
        }
        .bar-background {
          position: absolute;
          width: 100%;
          height: 100%;
          background-color: #2f3542;
          border-radius: 4px;
        }
        .bar {
          position: absolute;
          height: 100%;
          border-radius: 4px;
        }
        .long-bar, .long-profitable-bar {
          background-color: var(--primary-color);
        }
        .short-bar, .short-profitable-bar {
          background-color: var(--text-color);
        }
      `}</style>
    </div>
  );
};

export default TradeProgressBar;