import React, { useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { FaTelegram } from 'react-icons/fa';
import './../../css/navbar/navbar.css';

const Navbar = () => {
    const [walletAddress, setWalletAddress] = useState('');
    const navigate = useNavigate();

    const handleSubmit = (e) => {
        e.preventDefault();
        if (walletAddress) {
            navigate(`/portfolio?address=${walletAddress}`);
        }
        setWalletAddress('');
    };

    return (
        <nav className="navbar">
            <div className="navbar-container">
                <NavLink to="/" className="navbar-logo">
                    <h1>ASXN</h1>
                </NavLink>
                <ul className="navbar-links">
                    <li>
                        <NavLink
                            to="/all_metrics"
                            className={({ isActive }) => isActive ? 'active' : ''}
                        >
                            All Metrics
                        </NavLink>
                    </li>
                    <li>
                        <NavLink
                            to="/symbol_wise_metrics"
                            className={({ isActive }) =>
                                `${isActive ? 'active' : ''}`
                            }
                        >
                            Symbol Metrics
                        </NavLink>
                    </li>
                    <li>
                        <NavLink
                            to="/vault_metrics"
                            className={({ isActive }) =>
                                `${isActive ? 'active' : ''}`
                            }
                        >
                            Vault Metrics
                        </NavLink>
                    </li>
                    <li>
                        <NavLink
                            to="/portfolio"
                            className={({ isActive }) => isActive ? 'active' : ''}
                        >
                            Portfolio
                        </NavLink>
                    </li>
                    <li>
                        <a
                            href="https://app.hyperliquid.xyz/join/ASXN"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="support-link"
                        >
                            {/* <Heart size={16} /> */}
                            Trade on Hyperliquid
                        </a>
                    </li>
                    <li>
                        <a
                            href="https://t.me/+FbZod8E8mHdhYjk8"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="telegram-link"
                            title="Join our Telegram group"
                        >
                            <FaTelegram size={32} color={"#4d81ea"} />
                        </a>
                    </li>
                </ul>
                <div className="navbar-search">
                    <form onSubmit={handleSubmit} className="">
                        <input
                            type="text"
                            value={walletAddress}
                            onChange={(e) => setWalletAddress(e.target.value)}
                            placeholder="Enter wallet address"
                            className="navbar_modern-input"
                        />
                        <button type="submit" className="navbar_analyze_button">
                            Analyze
                        </button>
                    </form>
                </div>
            </div>
        </nav>
    );
};

export default Navbar;